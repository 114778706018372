import React from 'react';
import { Col, Row } from 'antd';

// Hooks
import { useParams } from 'react-router';
import { usePassTemplateDetail } from '../../hooks/PassTemplates/usePassTemplateDetail';

// Components
import { Container } from '../../components/UI/Base';
import { ContactCard, ProfileHeader } from '../../components/PassTemplates';
import { FormDrawer } from '../../components/UI/Drawer/FormDrawer';
import { BreadcrumbMenu } from '../../components/UI/Breadcrumb/BreadcrumbMenu';
import { PreviewCard } from '../../components/PassTemplates/PreviewCard';
import { PassTemplateSettings } from '../../components/PassTemplates/PassTemplateSettings';
import { PassTemplateFormDrawer } from '../../components/UI/Drawer/PassTemplateFormDrawer';

export const PassTemplateDetailPage = () => {
  const { id } = useParams();

  const {
    getBreadcrumbMenuProps,
    getProfileHeaderProps,
    getFormDrawerProps,
    getContactCardProps,
    getPassTemplateTenantsProps,
    getAssetsFormDrawerProps,
  } = usePassTemplateDetail({ id });

  return (
    <>
      <BreadcrumbMenu {...getBreadcrumbMenuProps()} />

      <ProfileHeader {...getProfileHeaderProps()} />

      <Container className="gx-profile-content">
        <Row>
          <Col xl={16} lg={14} md={14} sm={24} xs={24}>
            <PreviewCard
              {...getContactCardProps()}
              setTextElements={getContactCardProps().setTextElements}
              textElements={getContactCardProps().textElements}
              handleAssetClick={getContactCardProps().handleAssetClick}
            />
          </Col>
          <Col xl={8} lg={10} md={10} sm={24} xs={24}>
            <ContactCard
              {...getContactCardProps()}
              setAssetsType={getContactCardProps().setAssetsType}
              setTextElements={getContactCardProps().setTextElements}
              setSelectedId={getContactCardProps().setSelectedAssetId}
              setNewAddedAsset={getContactCardProps().setNewAddedAsset}
            />
          </Col>
        </Row>
        <Row>
          <Col xl={16} lg={14} md={14} sm={24} xs={24}>
            <PassTemplateSettings {...getPassTemplateTenantsProps()} />
          </Col>
        </Row>
      </Container>

      <FormDrawer {...getFormDrawerProps()} />
      <PassTemplateFormDrawer {...getAssetsFormDrawerProps()} />
    </>
  );
};
