import React, { useEffect } from 'react';
import { Button, Col, Row } from 'antd';
import { Translated } from '../UI/Core';
import { Widget } from '../UI/Widget/Widget';
import { useAppDispatch, useAppSelector } from '../../hooks/App/useRedux';
import { Spinner } from '../UI/Spinner/Spinner';
import { PassTemplate, PassTemplateAsset, PassTemplateAssetType } from '../../models/PassTemplate';
import { updatePassTemplateAsset } from '../../store/PassTemplates/PassTemplates.redux';

interface ContactCardProps {
  passTemplate: PassTemplate | null;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setAssetsType: React.Dispatch<React.SetStateAction<PassTemplateAssetType>>;
  setTextElements: React.Dispatch<React.SetStateAction<PassTemplateAsset[]>>;
  setSelectedId: React.Dispatch<React.SetStateAction<string>>;
  setNewAddedAsset: React.Dispatch<React.SetStateAction<string>>;
}

export const ContactCard = ({
  passTemplate,
  setOpen,
  setAssetsType,
  setTextElements,
  setSelectedId,
  setNewAddedAsset,
}: ContactCardProps) => {
  const { updating, lastAssetId } = useAppSelector(({ passTemplates }) => passTemplates);
  const dispatch = useAppDispatch();
  const handleAddElement = (type: PassTemplateAssetType, options?: Partial<PassTemplateAsset>) => {
    const defaultProperties: PassTemplateAsset = {
      Id: crypto.randomUUID(),
      Type: type,
      X: 120,
      Y: 10,
      Layer: 1,
      Rotation: 0,
      Opacity: 1,
      BorderRadius: 0,
      ShadowSize: 0,
      ShadowColor: '#000',
      Text: type === PassTemplateAssetType.Text ? 'Text' : '',
      Font: 'Arial',
      FontSize: 16,
      FontColor: '#000',
      TemplateId: passTemplate?.Id,
      Image: type === PassTemplateAssetType.Image ? { FileData: undefined, Url: '', MimeType: '' } : undefined,
      Width: type === PassTemplateAssetType.Image || PassTemplateAssetType.ProfilePicture ? 50 : 150,
      Height: type === PassTemplateAssetType.Image || PassTemplateAssetType.ProfilePicture ? 100 : 200,
      DisplayMode: type === PassTemplateAssetType.Image || PassTemplateAssetType.ProfilePicture ? 'Stretch' : 'None',
    };

    const newElement = { ...defaultProperties, ...options }; // Merge with optional custom options

    dispatch(updatePassTemplateAsset(newElement));

    setAssetsType(type);
    setTextElements((prev) => [...prev, newElement]);
    setSelectedId(newElement.Id);
    setNewAddedAsset('NewAsset');
  };

  useEffect(() => {
    if (lastAssetId) {
      setSelectedId(lastAssetId);
    }
  }, [lastAssetId, setOpen, setSelectedId]);

  return (
    <Widget title={<Translated id="passTemplates.assets" />} styleName="gx-card-profile-sm">
      <Spinner spinning={!!updating}>{/* Type */}</Spinner>
      <Row>
        <Col xs={{ span: 12, offset: 1 }} lg={{ span: 12, offset: 6 }}>
          <Button
            onClick={() => handleAddElement(PassTemplateAssetType.Text, { Text: 'Text Label', X: 30 })}
            ghost
            type="primary"
            key="Text"
            size="small"
            style={{
              width: '100%',
            }}
          >
            <span>
              <Translated id="passTemplate.add.text" defaultMessage="Text" />
            </span>
          </Button>
        </Col>
        <Col xs={{ span: 12, offset: 1 }} lg={{ span: 12, offset: 6 }}>
          <Button
            onClick={() =>
              handleAddElement(PassTemplateAssetType.Image, {
                Image: {
                  FileData: undefined, // You can populate this if needed
                  Url: 'path/to/image.jpg', // Correct way to pass image URL
                  MimeType: 'image/jpeg', // Set appropriate MIME type
                },
                Width: 100,
                Height: 50,
              })
            }
            ghost
            type="primary"
            key="Image"
            size="small"
            style={{
              width: '100%',
            }}
          >
            <span>
              <Translated id="passTemplate.add.image" defaultMessage="Image" />
            </span>
          </Button>
        </Col>
      </Row>

      <Row>
        <Col xs={{ span: 12, offset: 1 }} lg={{ span: 12, offset: 6 }}>
          <Button
            onClick={() => handleAddElement(PassTemplateAssetType.FirstName, { Text: 'First Name', X: 150 })}
            ghost
            type="primary"
            key="FirstName"
            size="small"
            style={{
              width: '100%',
            }}
          >
            <span>
              <Translated id="passTemplate.add.firstName" defaultMessage="FirstName" />
            </span>
          </Button>
        </Col>
        <Col xs={{ span: 12, offset: 1 }} lg={{ span: 12, offset: 6 }}>
          <Button
            onClick={() => handleAddElement(PassTemplateAssetType.LastName, { Text: 'Last Name', X: 200, Y: 10 })}
            ghost
            type="primary"
            key="LastName"
            size="small"
            style={{
              width: '100%',
            }}
          >
            <span>
              <Translated id="passTemplate.add.lastName" defaultMessage="LastName" />
            </span>
          </Button>
        </Col>
      </Row>

      <Row>
        <Col xs={{ span: 12, offset: 1 }} lg={{ span: 12, offset: 6 }}>
          <Button
            onClick={() => handleAddElement(PassTemplateAssetType.Email, { Text: 'Email', X: 240, Y: 30 })}
            ghost
            type="primary"
            key="Email"
            size="small"
            style={{
              width: '100%',
            }}
          >
            <span>
              <Translated id="passTemplate.add.email" defaultMessage="Email" />
            </span>
          </Button>
        </Col>
        <Col xs={{ span: 12, offset: 1 }} lg={{ span: 12, offset: 6 }}>
          <Button
            onClick={() => handleAddElement(PassTemplateAssetType.Department, { Text: 'Department', X: 200, Y: 50 })}
            ghost
            type="primary"
            key="Department"
            size="small"
            style={{
              width: '100%',
            }}
          >
            <span>
              <Translated id="passTemplate.add.department" defaultMessage="Department" />
            </span>
          </Button>
        </Col>
      </Row>

      <Row>
        <Col xs={{ span: 12, offset: 1 }} lg={{ span: 12, offset: 6 }}>
          <Button
            onClick={() => handleAddElement(PassTemplateAssetType.ProfilePicture, { X: 200, Y: 60 })}
            ghost
            type="primary"
            key="Picture"
            size="small"
            style={{
              width: '100%',
            }}
          >
            <span>
              <Translated id="passTemplate.add.picture" defaultMessage="Picture" />
            </span>
          </Button>
        </Col>
      </Row>
    </Widget>
  );
};
