import React, { useEffect, useState } from 'react';
import { Button, Drawer, DrawerProps, Form as AntForm } from 'antd';

import styled from 'styled-components';
import { Translated } from '../Core';
import { useResetFormOnOpen } from '../../../hooks/Drawer/useResetFormOnOpen';
import { FormOptions } from '../../../types/Table';

export interface FormDrawerProps<T extends object> extends FormOptions<T> {
  updating: boolean;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  size: DrawerProps['size'];
  placement: DrawerProps['placement'];
}

const StyledDrawer = styled(Drawer)`
  && .ant-drawer-content-wrapper {
    width: ${({ placement, size }) =>
      placement === 'bottom' ? '100%' : size === 'large' ? '736px' : '378px'} !important;
    height: ${({ placement }) => (placement === 'bottom' ? '30%' : 'auto')} !important;
  }
`;

export const FormDrawer = <T extends object>({
  Form,
  labels,
  updating,
  open,
  setOpen,
  size,
  placement,
}: FormDrawerProps<T>) => {
  // Form
  const [form] = AntForm.useForm();
  useResetFormOnOpen({ form, open });

  const [drawerPlacement, setDrawerPlacement] = useState<DrawerProps['placement']>('right');

  useEffect(() => {
    // Set placement based on the `placement` prop, default to 'right'
    if (placement) {
      setDrawerPlacement(placement as DrawerProps['placement']);
    }
  }, [placement]);

  // Button Handling
  const onClose = () => {
    setOpen(false);
  };
  const onConfirm = () => {
    form.submit();
  };

  return (
    <StyledDrawer
      title={labels.drawerForm ?? <Translated id="form.add" />}
      onClose={onClose}
      placement={drawerPlacement}
      open={open}
      size={size}
      headerStyle={{ padding: '8px 0px 16px 0' }}
      bodyStyle={{ padding: '16px 0px 16px 0' }}
      footerStyle={{ padding: '16px 0px 0px 0' }}
      footer={
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button onClick={onClose} style={{ marginBottom: 0, marginRight: 8 }}>
            <Translated id="form.cancelButton" />
          </Button>
          <Button loading={updating} onClick={onConfirm} style={{ marginBottom: 0 }} type="primary" htmlType="submit">
            {labels.submitButton ?? <Translated id="form.createButton" />}
          </Button>
        </div>
      }
    >
      <Form form={form} />
    </StyledDrawer>
  );
};
