import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router';
import { Store } from 'antd/lib/form/interface';
import { usePrevious } from 'react-use';

// Hooks
import { usePassTemplatesRedux } from './usePassTemplatesRedux';
import { useFormDrawer } from '../Drawer/useFormDrawer';
import { useData } from '../App/useData';

// Services
import { notificationService } from '../../services/Notifications/NotificationService';

// Data
import { ApiEndpoints } from '../../data/ApiEndpoints';
import { appBreadcrumbs } from '../../data/Breadcrumbs/Breadcrumbs';
import { PassTemplate, PassTemplateAsset, PassTemplateAssetType } from '../../models/PassTemplate';
import { usePassTemplatesAssetsRedux } from './usePassTemplatesAssetsRedux';
import { useAppDispatch } from '../App/useRedux';
import { usePassTemplateFormDrawer } from '../Drawer/usePassTemplateFormDrawer';
import { updatePassTemplateAsset } from '../../store/PassTemplates/PassTemplates.redux';

// Props
interface PassTemplateDetailProps {
  id?: string;
}

export const usePassTemplateDetail = <T extends object>({ id }: PassTemplateDetailProps) => {
  // Navigation Hook
  const navigate = useNavigate();

  // Data
  const { data: passTemplateResponse, fetch, pending } = useData(ApiEndpoints.passTemplates.detail, null);
  const prevPending = usePrevious(pending);

  // Component State
  const [passTemplateData, setPassTemplateData] = useState<PassTemplate>();
  const [textElements, setTextElements] = useState<PassTemplateAsset[]>([]);
  const [assetsType, setAssetsType] = useState(PassTemplateAssetType.Text);
  const [selectedAssetId, setSelectedAssetId] = useState(''); // selected asset id
  const [newAddedAsset, setNewAddedAsset] = useState(''); // newly added asset
  const [fileError, setFileError] = useState<string | null>(null);

  // Redux
  const { formOptions, updating, error, showDeleteConfirm, size } = usePassTemplatesRedux<T>({
    isEditing: true,
    initialValues: passTemplateResponse as Store,
  });
  const dispatch = useAppDispatch();

  const textElementsRef = useRef<PassTemplateAsset[]>(textElements); // Initialize with initial state

  // Keep the ref in sync with the actual state
  useEffect(() => {
    textElementsRef.current = textElements;
  }, [textElements]);

  const handleSubmit = useCallback(() => {
    const currentTextElements = textElementsRef.current; // Access the latest value via the ref
    // Find the element matching the selectedAssetId
    const matchingElement = currentTextElements.find((element) => element.Id === selectedAssetId) as PassTemplateAsset;

    dispatch(updatePassTemplateAsset(matchingElement));
    setNewAddedAsset('');
  }, [dispatch, selectedAssetId]);

  const {
    formOptions: assetsFormOptions,
    updating: assetsUpdating,
    error: assetsError,
    size: assetsSize,
    showDeleteAssetConfirm,
  } = usePassTemplatesAssetsRedux<T>({
    initialValues: passTemplateResponse as Store,
    setTextElements,
    selectedAssetId,
    assetsType,
    onSubmit: handleSubmit,
    setFileError,
    fileError,
  });

  const prevUpdating = usePrevious(updating);

  useEffect(() => {
    if (passTemplateResponse?.Assets) {
      setTextElements(
        passTemplateResponse.Assets.map((asset) => ({
          ...asset,
          TemplateId: passTemplateResponse.Id, // Ensure TemplateId is added when load the asset from db
        }))
      );
    } else {
      setTextElements([]);
    }
  }, [passTemplateResponse]);

  // Form Drawer
  const { getFormDrawerProps } = useFormDrawer<T>({ formOptions, updating, error, size });

  const { getFormDrawerProps: getAssetsFormDrawerProps } = usePassTemplateFormDrawer<T>({
    formOptions: assetsFormOptions,
    updating: assetsUpdating,
    error: assetsError,
    size: assetsSize,
    placement: 'bottom',
    showDeleteConfirm: showDeleteAssetConfirm,
    newAsset: newAddedAsset,
    setNewAddedAsset,
  });

  useEffect(() => {
    if (selectedAssetId && newAddedAsset === 'NewAsset') {
      getAssetsFormDrawerProps().setOpen(true);
    }
  }, [getAssetsFormDrawerProps, newAddedAsset, selectedAssetId]);

  const handleAssetClick = useCallback(
    (element: PassTemplateAsset) => {
      getAssetsFormDrawerProps().setOpen(true);
      setSelectedAssetId(element.Id);
      setAssetsType(element.Type);
      setNewAddedAsset('');
      setFileError(null);
    },
    [getAssetsFormDrawerProps]
  );

  // Props
  const getBreadcrumbMenuProps = useCallback(() => ({ breadcrumbs: appBreadcrumbs.passTemplates.detail }), []);
  const getProfileHeaderProps = useCallback(
    () => ({
      passTemplateData,
      pending,
      setOpen: getFormDrawerProps().setOpen,
      showDeleteConfirm,
    }),
    [passTemplateData, pending, getFormDrawerProps, showDeleteConfirm]
  );

  const getContactCardProps = useCallback(
    () => ({
      passTemplate: passTemplateResponse,
      pending,
      setOpen: getAssetsFormDrawerProps().setOpen,
      assetsType,
      textElements,
      setTextElements,
      setAssetsType,
      handleAssetClick,
      setSelectedAssetId,
      setNewAddedAsset,
      setFileError,
    }),
    [passTemplateResponse, pending, getAssetsFormDrawerProps, assetsType, textElements, handleAssetClick]
  );

  const getPassTemplateTenantsProps = useCallback(
    () => ({
      passTemplateResponse,
      fetchPassTemplate: fetch,
      updatingPassTemplate: pending,
    }),
    [passTemplateResponse, fetch, pending]
  );

  // Effects
  useEffect(() => {
    // Fetch on initializing
    fetch({ id });
  }, [fetch, id]);

  useEffect(() => {
    // Navigates to the index if not found
    if (prevPending === true && !pending) {
      if (!passTemplateResponse) {
        notificationService.showError('passTemplates.notFound');
        navigate('/PassTemplates');
      }
      // Update Component State based on API response
      else {
        setPassTemplateData(passTemplateResponse);
      }
    }
  }, [prevPending, pending, passTemplateResponse, navigate]);

  useEffect(() => {
    // Fetch after updating
    if (prevUpdating === true && !updating && !error) {
      fetch({ id });
    }
  }, [prevUpdating, updating, error, fetch, id, size]);

  return useMemo(
    () => ({
      updating,
      getBreadcrumbMenuProps,
      getProfileHeaderProps,
      getContactCardProps,
      getPassTemplateTenantsProps,
      getFormDrawerProps,
      getAssetsFormDrawerProps,
    }),
    [
      updating,
      getBreadcrumbMenuProps,
      getProfileHeaderProps,
      getContactCardProps,
      getPassTemplateTenantsProps,
      getFormDrawerProps,
      getAssetsFormDrawerProps,
    ]
  );
};
