import React, { useCallback, useMemo } from 'react';
import { Button, Divider, Form, Input, InputNumber, Row, Space, Switch } from 'antd';
import FormItemLabel from 'antd/lib/form/FormItemLabel';
import styled from 'styled-components';
import { useIntl } from 'react-intl';

// Models
import { AzureTenant, AzureTenantSettingsFormValues } from '../../../models/AzureTenant';

// Redux
import { useAppDispatch, useAppSelector } from '../../App/useRedux';

// Actions
import { updateAzureTenant } from '../../../store/AzureTenants/AzureTenants.redux';

// Components
import { Translated } from '../../../components/UI/Core';

const { Item } = Form;

// Styled
const NoSidePaddingFormItem = styled(Item)`
  margin-bottom: 8px;

  & .ant-form-item-control {
    padding-left: 0;
    padding-right: 0;
  }
`;
const NoMarginBottomFormItem = styled(NoSidePaddingFormItem)`
  margin-bottom: 0px;
`;
const NoBottomMarginFormItem = styled(Item)`
  margin-bottom: 0;
`;
const StyledSpace = styled(Space)`
  width: 100%;
  margin-bottom: 0;

  & .ant-space-item {
    width: 100%;
  }
`;
const StyledButton = styled(Button)`
  margin-bottom: 0;
`;

// Props
interface SettingsReduxProps {
  azureTenant: AzureTenant | undefined;
}

// Hook
export const useSettingsRedux = ({ azureTenant }: SettingsReduxProps) => {
  // Intl
  const intl = useIntl();

  // Redux
  const dispatch = useAppDispatch();
  const updating = useAppSelector(({ azureTenants }) => azureTenants?.updating ?? false);
  const error = useAppSelector(({ azureTenants }) => azureTenants?.error ?? false);

  // Form Values
  const initialValues: AzureTenantSettingsFormValues = useMemo(
    () => ({
      Id: azureTenant?.Id,
      Name: azureTenant?.Name,
      AzureTenantId: azureTenant?.AzureTenantId,
      ClientId: azureTenant?.ClientId,
      ClientSecret: azureTenant?.ClientSecret,
      UseSso: azureTenant?.UseSso,
      ContractEndTolerance: azureTenant?.ContractEndTolerance,
      EmployeeIdRequired: azureTenant?.EmployeeIdRequired,
      DeleteMissingEmployees: azureTenant?.DeleteMissingEmployees,
      FilterByGroups: azureTenant?.FilterByGroups,
      TenantId: azureTenant?.TenantId,
      Groups: azureTenant?.Groups ?? [],
    }),
    [azureTenant]
  );

  // Components
  const SsoSettingsForm = useCallback(
    () => (
      <Form
        layout="vertical"
        initialValues={initialValues}
        onFinish={(values: AzureTenant) => dispatch(updateAzureTenant(values))}
      >
        {/* Hidden Values */}
        <Item name="Id" hidden>
          <Input />
        </Item>
        <Item name="Name" hidden>
          <Input />
        </Item>
        <Item name="TenantId" hidden>
          <Input />
        </Item>
        <Item name="ClientId" hidden>
          <Input />
        </Item>
        <Item name="ClientSecret" hidden>
          <Input />
        </Item>
        <Item name="ContractEndTolerance" hidden>
          <Input />
        </Item>
        <Item name="EmployeeIdRequired" hidden>
          <Input />
        </Item>
        <Item name="DeleteMissingEmployees" hidden>
          <Input />
        </Item>
        <Item name="FilterByGroups" hidden>
          <Input />
        </Item>

        {/* Form Values */}
        <StyledSpace align="start">
          <NoSidePaddingFormItem
            name={['AzureTenantId']}
            label={<Translated id="azureTenants.azureTenantId" />}
            rules={[
              {
                required: true,
                message: intl.formatMessage({ id: 'azureTenants.form.warnings.azureTenantId' }),
              },
            ]}
          >
            <Input />
          </NoSidePaddingFormItem>
        </StyledSpace>

        <Space>
          <FormItemLabel label={<Translated id="azureTenants.useSso" />} prefixCls="cls" />
          <NoMarginBottomFormItem name={['UseSso']} valuePropName="checked">
            <Switch checkedChildren={<Translated id="app.yes" />} unCheckedChildren={<Translated id="app.no" />} />
          </NoMarginBottomFormItem>
        </Space>

        <Divider />

        {/* Action Buttons */}
        <NoBottomMarginFormItem>
          <StyledButton type="primary" htmlType="submit">
            <Translated id="app.save" />
          </StyledButton>
        </NoBottomMarginFormItem>
      </Form>
    ),
    [initialValues, intl, dispatch]
  );

  const ImportSettingsForm = useCallback(
    () => (
      <Form
        layout="vertical"
        initialValues={initialValues}
        onFinish={(values: AzureTenant) => dispatch(updateAzureTenant(values))}
      >
        {/* Hidden Values */}
        <Item name="Id" hidden>
          <Input />
        </Item>
        <Item name="Name" hidden>
          <Input />
        </Item>
        <Item name="TenantId" hidden>
          <Input />
        </Item>
        <Item name="AzureTenantId" hidden>
          <Input />
        </Item>
        <Item name="UseSso" hidden>
          <Input />
        </Item>

        {/* Form Values */}
        <StyledSpace align="start">
          <NoSidePaddingFormItem
            name={['ClientId']}
            label={<Translated id="azureTenants.clientId" />}
            rules={[
              {
                required: false,
              },
            ]}
          >
            <Input />
          </NoSidePaddingFormItem>
        </StyledSpace>

        <StyledSpace align="start">
          <NoSidePaddingFormItem
            name={['ClientSecret']}
            label={<Translated id="azureTenants.clientSecret" />}
            rules={[
              {
                required: false,
              },
            ]}
          >
            <Input />
          </NoSidePaddingFormItem>
        </StyledSpace>

        <StyledSpace align="start">
          <NoSidePaddingFormItem
            name={['ContractEndTolerance']}
            label={<Translated id="azureTenants.contractEndTolerance" />}
            rules={[
              () => ({
                validator(_, value) {
                  const num = Number.parseInt(value, 10);
                  if (!Number.isNaN(num)) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error(intl.formatMessage({ id: 'form.warnings.number' })));
                },
              }),
            ]}
          >
            <InputNumber
              type="number"
              pattern="[0-9]*"
              min={0}
              step={1}
              addonAfter={<Translated id="app.hours" />}
              style={{ width: '100%' }}
            />
          </NoSidePaddingFormItem>
        </StyledSpace>

        <Row>
          <Space>
            <FormItemLabel label={<Translated id="azureTenants.employeeIdRequired" />} prefixCls="cls" />
            <NoMarginBottomFormItem name={['EmployeeIdRequired']} valuePropName="checked">
              <Switch checkedChildren={<Translated id="app.yes" />} unCheckedChildren={<Translated id="app.no" />} />
            </NoMarginBottomFormItem>
          </Space>
        </Row>

        <Row>
          <Space>
            <FormItemLabel label={<Translated id="azureTenants.deleteMissingEmployees" />} prefixCls="cls" />
            <NoMarginBottomFormItem name={['DeleteMissingEmployees']} valuePropName="checked">
              <Switch checkedChildren={<Translated id="app.yes" />} unCheckedChildren={<Translated id="app.no" />} />
            </NoMarginBottomFormItem>
          </Space>
        </Row>

        <Row>
          <Space>
            <FormItemLabel label={<Translated id="azureTenants.filterByGroups" />} prefixCls="cls" />
            <NoMarginBottomFormItem name={['FilterByGroups']} valuePropName="checked">
              <Switch checkedChildren={<Translated id="app.yes" />} unCheckedChildren={<Translated id="app.no" />} />
            </NoMarginBottomFormItem>
          </Space>
        </Row>

        <Divider />

        {/* Action Buttons */}
        <NoBottomMarginFormItem>
          <StyledButton type="primary" htmlType="submit">
            <Translated id="app.save" />
          </StyledButton>
        </NoBottomMarginFormItem>
      </Form>
    ),
    [initialValues, intl, dispatch]
  );

  return useMemo(
    () => ({
      SsoSettingsForm,
      ImportSettingsForm,
      updating,
      error,
    }),
    [SsoSettingsForm, ImportSettingsForm, updating, error]
  );
};
