import React, { useCallback, useMemo } from 'react';
import styled from 'styled-components';
import Draggable, { DraggableData } from 'react-draggable';
import { Translated } from '../UI/Core';
import { Widget } from '../UI/Widget/Widget';
import { useAppDispatch, useAppSelector } from '../../hooks/App/useRedux';
import { Spinner } from '../UI/Spinner/Spinner';
import {
  PassTemplate,
  PassTemplateAsset,
  PassTemplateAssetType,
  PassTemplateFormValues,
} from '../../models/PassTemplate';
import { PassTemplateSizeType } from '../../models/enums/PassTemplates/PassTemplateType';
import { PassTemplateBackgroundType } from '../../models/enums/PassTemplates/PassTemplateBackground';
import { BackgroundSizeType } from '../../models/enums/BackgroundSize';
import { PassTemplateOrientationType } from '../../models/enums/PassTemplates/PassTemplateOrientation';
import { updatePassTemplateAssetPosition } from '../../store/PassTemplates/PassTemplates.redux';

interface PreviewCardProps {
  passTemplate: PassTemplate | null;
  textElements: any;
  handleAssetClick: (element: PassTemplateAsset) => void; // Add the onTextClick prop
  setTextElements: React.Dispatch<React.SetStateAction<PassTemplateAsset[]>>;
}

const AspectRatios: Record<PassTemplateSizeType, Record<PassTemplateOrientationType, number>> = {
  [PassTemplateSizeType.Standard]: {
    [PassTemplateOrientationType.Portrait]: 85 / 55,
    [PassTemplateOrientationType.Landscape]: 55 / 85,
  },
  [PassTemplateSizeType.Slim]: {
    [PassTemplateOrientationType.Portrait]: 85 / 25,
    [PassTemplateOrientationType.Landscape]: 25 / 85,
  },
  [PassTemplateSizeType.Square]: {
    [PassTemplateOrientationType.Portrait]: 1,
    [PassTemplateOrientationType.Landscape]: 1,
  },
};

const BackgroundSize: Record<BackgroundSizeType, string> = {
  [BackgroundSizeType.Auto]: 'auto',
  [BackgroundSizeType.Contain]: 'contain',
  [BackgroundSizeType.Cover]: 'cover',
};

const allowedTypes = [
  PassTemplateAssetType.Text, // 0
  PassTemplateAssetType.FirstName, // 2
  PassTemplateAssetType.LastName, // 3
  PassTemplateAssetType.Email, // 4
  PassTemplateAssetType.Department, // 5
];

const CardContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: left;
  width: auto;
`;

const PassTemplateCard = styled(Widget)`
  height: 25rem;
  width: auto;
  position: relative;
  overflow: hidden;
  box-shadow: rgba(0, 0, 0, 0.03) 0px 0px 2px 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
`;

export const PreviewCard = ({ passTemplate, textElements, handleAssetClick, setTextElements }: PreviewCardProps) => {
  // Hooks
  const { updatingSettings } = useAppSelector(({ passTemplates }) => passTemplates);
  const dispatch = useAppDispatch();
  const defaultProfilePicture =
    'https://as2.ftcdn.net/jpg/02/60/78/83/500_F_260788352_x5sSHM4DGvpjHj9wz8sFltzAPktQwJCj.jpg';

  // Form Values
  const initialValues: PassTemplateFormValues = useMemo(
    () => ({
      Id: passTemplate?.Id,
      Name: passTemplate?.Name,
      BackgroundType: passTemplate?.BackgroundType,
      BackgroundImage: passTemplate?.BackgroundImage,
      BackgroundColor: passTemplate?.BackgroundColor,
      BackgroundSize: passTemplate?.BackgroundSize,
      Orientation: passTemplate?.Orientation,
      Type: passTemplate?.Type,
      EnabledForVisitors: passTemplate?.EnabledForVisitors,
      EnabledForEmployees: passTemplate?.EnabledForEmployees,
      Tenants: [],
    }),
    [passTemplate]
  );

  const Draggable1: any = Draggable;

  const handleDragStop = useCallback(
    (element: PassTemplateAsset) => (e: Event, data: DraggableData) => {
      const card = document.getElementById('passTemplateCard')?.getBoundingClientRect();
      if (!card) return;

      const draggedElement = document.getElementById(`element-${element.Id}`);

      if (!draggedElement) return;
      const { height: elementHeight } = draggedElement.getBoundingClientRect();

      // Calculate boundaries
      const minX = 0;
      const maxX = card.width - 20;
      const minY = 0;
      const maxY = card.height - elementHeight;

      // Clamp the position to the card boundaries
      const clampedX = Math.floor(Math.max(minX, Math.min(Number(data.x), maxX)));
      const clampedY = Math.floor(Math.max(minY, Math.min(Number(data.y), maxY)));

      // Only update the dragged element's position in the state
      const updatedElements = textElements.map(
        (el: PassTemplateAsset) =>
          el.Id === element.Id // Compare using `element.Id` to ensure the correct element is updated
            ? { ...el, X: clampedX, Y: clampedY } // Update position for the dragged element
            : el // Keep other elements the same
      );

      // Update the state with the new positions of the elements
      setTextElements(updatedElements);

      // Find asset to update postions
      const matchingAsset = updatedElements.find(
        (asset: PassTemplateAsset) => asset.Id === element.Id
      ) as PassTemplateAsset;

      dispatch(updatePassTemplateAssetPosition(matchingAsset));
    },
    [textElements, setTextElements, dispatch] // Dependencies of the callback
  );

  const renderElementContent = (element: PassTemplateAsset) => {
    if (allowedTypes.includes(element.Type)) {
      // Render text element
      return (
        <div
          style={{
            fontSize: `${element.FontSize}px`,
            fontFamily: element.Font,
            color: element.FontColor,
            transform: `rotate(${element.Rotation}deg)`,
          }}
        >
          {element.Text}
        </div>
      );
    }

    if (element.Type === PassTemplateAssetType.Image) {
      // Render image element
      return (
        <img
          draggable="false"
          src={element.Image?.Url}
          alt=""
          style={{
            width: `${element.Width}px`,
            height: `${element.Height}px`,
            borderRadius: `${element.BorderRadius}%`,
            transform: `rotate(${element.Rotation}deg)`,
            pointerEvents: 'none',
            objectFit: element.DisplayMode as 'fill' | 'contain' | 'cover' | 'none' | 'scale-down',
          }}
        />
      );
    }

    if (element.Type === PassTemplateAssetType.ProfilePicture) {
      // Render profile picture with default image fallback
      return (
        <img
          draggable="false"
          src={element.Image?.Url || defaultProfilePicture}
          alt=""
          style={{
            width: `${element.Width}px`,
            height: `${element.Height}px`,
            borderRadius: `${element.BorderRadius}%`,
            transform: `rotate(${element.Rotation}deg)`,
            pointerEvents: 'none',
            objectFit: 'cover',
          }}
        />
      );
    }

    return null;
  };

  const SettingsForm = useCallback(
    () => (
      <CardContainer>
        <PassTemplateCard
          id="passTemplateCard"
          style={{
            aspectRatio:
              AspectRatios[initialValues.Type ?? PassTemplateSizeType.Standard][
                initialValues.Orientation ?? PassTemplateOrientationType.Landscape
              ],
            background:
              initialValues.BackgroundType === PassTemplateBackgroundType.Image
                ? `center / ${BackgroundSize[initialValues.BackgroundSize || BackgroundSizeType.Auto]} no-repeat url("${
                    initialValues.BackgroundImage?.Url
                  }") #eee`
                : initialValues.BackgroundColor || '#ffffff',
          }}
        />

        {textElements.map((element: PassTemplateAsset) => {
          let dragStart = 0;
          return (
            <Draggable1
              key={element.Id}
              bounds="#passTemplateCard"
              scale={1}
              defaultPosition={{ x: element.X, y: element.Y }}
              onStart={() => {
                dragStart = Date.now();
              }}
              onStop={(e: any, data: DraggableData) => {
                // If the user lets go of the mouse within 150ms then consider it a click.
                const time = Date.now() - dragStart;
                if (time < 150) {
                  handleAssetClick(element);
                } else {
                  // Update the position of the dragged element
                  handleDragStop(element)(e, data);
                }
              }}
            >
              <div
                id={`element-${element.Id}`}
                style={{
                  position: 'absolute',
                  opacity: element.Opacity,
                  top: '0px',
                  left: '0px',
                  transform: `rotate(${element.Rotation}deg)`,
                  boxShadow: `${element.ShadowSize}px ${element.ShadowSize}px ${element.ShadowSize}px ${element.ShadowColor}`,
                  borderRadius: `${element.BorderRadius}%`,
                  transformOrigin: 'center center',
                  zIndex: `${element.Layer}`,
                }}
              >
                {renderElementContent(element)}
              </div>
            </Draggable1>
          );
        })}
      </CardContainer>
    ),
    [
      Draggable1,
      handleDragStop,
      initialValues.BackgroundColor,
      initialValues.BackgroundImage?.Url,
      initialValues.BackgroundSize,
      initialValues.BackgroundType,
      initialValues.Orientation,
      initialValues.Type,
      handleAssetClick,
      textElements,
    ]
  );

  return (
    <Widget title={<Translated id="passTemplates.preview" />} styleName="gx-card-profile-sm">
      <Spinner spinning={!!updatingSettings}>
        <SettingsForm />
      </Spinner>
    </Widget>
  );
};
