/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-param-reassign */

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// Models
import { SignalRAction } from '../../models/SignalRAction';
import { PassTemplate, PassTemplateAsset } from '../../models/PassTemplate';

// State
interface PassTemplateState {
  updating: boolean;
  updatingSettings: boolean;
  error: boolean;
  traceId?: string;
  lastAssetId?: string;
}

const initialState: PassTemplateState = {
  updating: false,
  updatingSettings: false,
  error: false,
};

// Name
const storeName = '@PASS_TEMPLATE';

// Redux Actions|Reducers
const PassTemplateSlice = createSlice({
  name: storeName,
  initialState,
  reducers: {
    // Create
    createPassTemplate: (state, action: PayloadAction<PassTemplate>) => {
      state.updating = true;
      state.error = false;
    },
    passTemplateCreated: (state, action: PayloadAction<SignalRAction>) => {
      state.updating = false;
      state.error = false;
    },
    passTemplateNotCreated: (state, action: PayloadAction<SignalRAction>) => {
      state.updating = false;
      state.error = true;
    },

    // Update
    updatePassTemplate: (state, action: PayloadAction<PassTemplate>) => {
      state.updating = true;
      state.error = false;
    },
    passTemplateUpdated: (state, action: PayloadAction<SignalRAction>) => {
      state.updating = false;
      state.error = false;
    },
    passTemplateNotUpdated: (state, action: PayloadAction<SignalRAction>) => {
      state.updating = false;
      state.error = true;
    },

    // Delete
    deletePassTemplate: (state, action: PayloadAction<PassTemplate>) => {
      state.updating = true;
      state.error = false;
    },
    passTemplateDeleted: (state, action: PayloadAction<SignalRAction>) => {
      state.updating = false;
      state.error = false;
    },
    passTemplateNotDeleted: (state, action: PayloadAction<SignalRAction>) => {
      state.updating = false;
      state.error = true;
    },

    // Delete All
    deletePassTemplates: (state, action: PayloadAction<Array<PassTemplate>>) => {
      state.updating = true;
      state.error = false;
    },
    passTemplatesDeleted: (state, action: PayloadAction<SignalRAction>) => {
      state.updating = false;
      state.error = false;
    },
    passTemplatesNotDeleted: (state, action: PayloadAction<SignalRAction>) => {
      state.updating = false;
      state.error = true;
    },

    // Fetch
    fetchPassTemplates: (state, action: PayloadAction<PassTemplate>) => {
      state.updating = true;
      state.error = false;
    },
    passTemplatesFetched: (state, action: PayloadAction<SignalRAction>) => {
      state.updating = false;
      state.error = false;
    },
    passTemplatesNotFetched: (state, action: PayloadAction<SignalRAction>) => {
      state.updating = false;
      state.error = true;
    },

    // Create Update Assets
    updatePassTemplateAsset: (state, action: PayloadAction<PassTemplateAsset>) => {
      state.updating = true;
      state.error = false;
    },
    setTraceId(state, _action: PayloadAction<string | undefined>) {
      state.traceId = _action.payload;
    },
    passTemplateAssetUpdated: (state, action: PayloadAction<SignalRAction>) => {
      state.updating = false;
      state.error = false;
      if (state.traceId !== undefined && action.payload.msg.traceId === state.traceId) {
        state.lastAssetId = action.payload.msg?.affectedEntities[0];
      }
    },
    passTemplateAssetNotUpdated: (state, action: PayloadAction<SignalRAction>) => {
      state.updating = false;
      state.error = true;
    },

    // Delete Assets
    deletePassTemplateAsset: (state, action: PayloadAction<{ Id: string; TemplateId: string }>) => {
      state.updating = true;
      state.error = false;
    },
    passTemplateAssetDeleted: (state, action: PayloadAction<SignalRAction>) => {
      state.updating = false;
      state.error = false;
    },
    passTemplateAssetNotDeleted: (state, action: PayloadAction<SignalRAction>) => {
      state.updating = false;
      state.error = true;
    },

    // Update Pass template Assets position
    updatePassTemplateAssetPosition: (state, action: PayloadAction<PassTemplateAsset>) => {
      state.updating = false;
      state.error = false;
    },
    passTemplateAssetPositionUpdated: (state, action: PayloadAction<SignalRAction>) => {
      state.updating = false;
      state.error = false;
    },
    passTemplateAssetPositionNotUpdated: (state, action: PayloadAction<SignalRAction>) => {
      state.updating = false;
      state.error = true;
    },
  },
});

// Export Actions
export const {
  // Create
  createPassTemplate,
  passTemplateCreated,
  passTemplateNotCreated,
  // Update
  updatePassTemplate,
  passTemplateUpdated,
  passTemplateNotUpdated,
  // Delete
  deletePassTemplate,
  passTemplateDeleted,
  passTemplateNotDeleted,
  // Delete All
  deletePassTemplates,
  passTemplatesDeleted,
  passTemplatesNotDeleted,
  // Fetch
  fetchPassTemplates,
  passTemplatesFetched,
  passTemplatesNotFetched,
  // Update passTemplate assets
  updatePassTemplateAsset,
  passTemplateAssetUpdated,
  passTemplateAssetNotUpdated,
  // Delete passTemplate assets
  deletePassTemplateAsset,
  passTemplateAssetDeleted,
  passTemplateAssetNotDeleted,
  // Update Pass template Assets position
  updatePassTemplateAssetPosition,
  passTemplateAssetPositionUpdated,
  passTemplateAssetPositionNotUpdated,
  // Set trace
  setTraceId,
} = PassTemplateSlice.actions;

// Export Reducer
export default PassTemplateSlice.reducer;
