// Types
import { SignalREvent } from '../../models/SignalREvent';

// Actions
import {
  passTemplateAssetDeleted,
  passTemplateAssetNotDeleted,
  passTemplateAssetNotUpdated,
  passTemplateAssetUpdated,
  passTemplateCreated,
  passTemplateDeleted,
  passTemplateNotCreated,
  passTemplateNotDeleted,
  passTemplateNotUpdated,
  passTemplateUpdated,
} from './PassTemplates.redux';
import { OperationType } from '../../models/SignalRAction';

// Events
const SignalREvents = {
  PassTemplateOperation: 'PassTemplateOperationIntegrationEvent',
  PassTemplateTenantsAssigned: `PassTemplateTenantsAssignedIntegrationEvent`,
  PassTemplateAssetOperation: 'PassTemplateAssetOperationIntegrationEvent',
};

// Exceptions
const SignalRExceptions = {
  PassTemplateOperationFailed: 'PassTemplateOperationIntegrationException',
  PassTemplateTenantsNotAssigned: `PassTemplateTenantsNotAssignedIntegrationException`,
  PassTemplateAssetOperationFailed: 'PassTemplateAssetOperationIntegrationException',
};

// Events
export const configureSignalREvents = ({ hubConnection, store, history }: SignalREvent) => {
  // CRUD Operations
  hubConnection.on(SignalREvents.PassTemplateOperation, (msg) => {
    const op = msg.operation as OperationType;
    switch (op) {
      case OperationType.Create:
        store.dispatch(passTemplateCreated({ history, msg }));
        break;
      case OperationType.Update:
        store.dispatch(passTemplateUpdated({ history, msg }));
        break;
      case OperationType.Delete:
        store.dispatch(passTemplateDeleted({ history, msg }));
        break;
      default:
        break;
    }
  });
  hubConnection.on(SignalRExceptions.PassTemplateOperationFailed, (msg) => {
    const op = msg.operation as OperationType;
    switch (op) {
      case OperationType.Create:
        store.dispatch(passTemplateNotCreated({ history, msg }));
        break;
      case OperationType.Update:
        store.dispatch(passTemplateNotUpdated({ history, msg }));
        break;
      case OperationType.Delete:
        store.dispatch(passTemplateNotDeleted({ history, msg }));
        break;
      default:
        break;
    }
  });

  // Pass template asset CRUD Operations
  hubConnection.on(SignalREvents.PassTemplateAssetOperation, (msg) => {
    const op = msg.operation as OperationType;
    switch (op) {
      case OperationType.Update:
        store.dispatch(passTemplateAssetUpdated({ history, msg }));
        break;
      case OperationType.Delete:
        store.dispatch(passTemplateAssetDeleted({ history, msg }));
        break;
      default:
        break;
    }
  });

  hubConnection.on(SignalRExceptions.PassTemplateAssetOperationFailed, (msg) => {
    const op = msg.operation as OperationType;
    switch (op) {
      case OperationType.Other:
      case OperationType.Update:
        store.dispatch(passTemplateAssetNotUpdated({ history, msg }));
        break;
      case OperationType.Delete:
        store.dispatch(passTemplateAssetNotDeleted({ history, msg }));
        break;
      default:
        break;
    }
  });
};
