import { Tenant } from './Tenant';
import { BackgroundSizeType } from './enums/BackgroundSize';
import { PassTemplateBackgroundType } from './enums/PassTemplates/PassTemplateBackground';
import { PassTemplateOrientationType } from './enums/PassTemplates/PassTemplateOrientation';
import { PassTemplateSizeType } from './enums/PassTemplates/PassTemplateType';

export interface PassTemplate {
  Id: string;
  Name: string;
  EnabledForVisitors: boolean;
  EnabledForEmployees: boolean;
  BackgroundType: PassTemplateBackgroundType;
  BackgroundColor: string;
  BackgroundImage: PassTemplateBackgroundFile;
  BackgroundSize: BackgroundSizeType;
  Orientation: PassTemplateOrientationType;
  Type: PassTemplateSizeType;
  Tenants: Array<Tenant>;
  Assets: Array<PassTemplateAsset>;
}

export interface PassTemplateBackgroundFile {
  FileData: string | undefined;
  Url: string;
  MimeType: string;
}

export interface PassTemplateFormValues {
  Id: string | undefined;
  Name: string | undefined;
  EnabledForVisitors: boolean | undefined;
  EnabledForEmployees: boolean | undefined;
  BackgroundType: PassTemplateBackgroundType | undefined;
  BackgroundColor: string | undefined;
  BackgroundImage: PassTemplateBackgroundFile | undefined;
  BackgroundSize: BackgroundSizeType | undefined;
  Orientation: PassTemplateOrientationType | undefined;
  Type: PassTemplateSizeType | undefined;
  Tenants: Array<Tenant> | undefined;
}

export interface PassTemplateAsset {
  Id: string;
  Type: PassTemplateAssetType;
  X: number;
  Y: number;
  Layer: number;
  Rotation: number;
  Opacity: number;
  BorderRadius: number;
  ShadowSize: number;
  ShadowColor: string;
  Text: string;
  Font: string;
  FontColor: string;
  FontSize: number;
  TemplateId?: string;
  Image: PassTemplateAssetFile | undefined;
  DisplayMode: string;
  Width: number;
  Height: number;
}

export enum PassTemplateAssetType {
  Text = 0,
  Image = 1,
  FirstName = 2,
  LastName = 3,
  Email = 4,
  Department = 5,
  ProfilePicture = 6,
}

export interface PassTemplateAssetFile {
  FileData: string | undefined;
  Url: string;
  MimeType: string;
}
