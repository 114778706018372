import { all, put, takeEvery, takeLatest } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';

// Services
import { ApiRequestType, apiService } from '../../services/ApiService/ApiService';
import { notificationService } from '../../services/Notifications/NotificationService';

// Models
import { SignalRAction } from '../../models/SignalRAction';
import { PassTemplate, PassTemplateAsset } from '../../models/PassTemplate';

// Redux
import {
  createPassTemplate,
  deletePassTemplate,
  deletePassTemplateAsset,
  deletePassTemplates,
  fetchPassTemplates,
  passTemplateAssetDeleted,
  passTemplateAssetNotDeleted,
  passTemplateAssetNotUpdated,
  passTemplateAssetPositionNotUpdated,
  passTemplateAssetPositionUpdated,
  passTemplateAssetUpdated,
  passTemplateCreated,
  passTemplateDeleted,
  passTemplateNotCreated,
  passTemplateNotDeleted,
  passTemplateNotUpdated,
  passTemplatesDeleted,
  passTemplatesFetched,
  passTemplatesNotDeleted,
  passTemplatesNotFetched,
  passTemplateUpdated,
  setTraceId,
  updatePassTemplate,
  updatePassTemplateAsset,
  updatePassTemplateAssetPosition,
} from './PassTemplates.redux';

// **************************************************
// ********************* CREATE *********************

// Worker Sagas
function* createPassTemplateSaga() {
  yield takeEvery(createPassTemplate.type, createPassTemplateRequest);
}

function* passTemplateCreatedSaga() {
  yield takeLatest(passTemplateCreated.type, createPassTemplateResponse);
}

function* passTemplateNotCreatedSaga() {
  yield takeLatest(passTemplateNotCreated.type, createPassTemplateError);
}

// Request
function* createPassTemplateRequest(action: PayloadAction<PassTemplate>) {
  try {
    const { payload: passTemplate } = action;
    yield apiService.execute({
      url: 'PassTemplates',
      method: ApiRequestType.POST,
      data: passTemplate,
    });
  } catch ({ message }) {
    yield put({ type: passTemplateNotCreated.type, payload: { msg: { message } } });
  }
}

// Response
function createPassTemplateResponse() {
  notificationService.showSuccess('passTemplates.notifications.create');
}

// Error
function createPassTemplateError(action: PayloadAction<SignalRAction>) {
  notificationService.showErrorWithContent('passTemplates.notifications.createFailed', action?.payload?.msg.message);
}

// **************************************************
// ********************* UPDATE *********************

// Worker Sagas
function* updatePassTemplateSaga() {
  yield takeEvery(updatePassTemplate.type, updatePassTemplateRequest);
}

function* passTemplateUpdatedSaga() {
  yield takeLatest(passTemplateUpdated.type, updatePassTemplateResponse);
}

function* passTemplateNotUpdatedSaga() {
  yield takeLatest(passTemplateNotUpdated.type, updatePassTemplateError);
}

// Request
function* updatePassTemplateRequest(action: PayloadAction<PassTemplate>) {
  try {
    const { payload: passTemplate } = action;

    yield apiService.execute({
      url: `PassTemplates/${passTemplate.Id}`,
      method: ApiRequestType.PUT,
      data: passTemplate,
    });
  } catch ({ message }) {
    yield put({ type: passTemplateNotUpdated.type, payload: { msg: { message } } });
  }
}

// Response
function updatePassTemplateResponse() {
  notificationService.showSuccess('passTemplates.notifications.update');
}

// Error
function updatePassTemplateError(action: PayloadAction<SignalRAction>) {
  notificationService.showErrorWithContent('passTemplates.notifications.updateFailed', action?.payload?.msg.message);
}

// **************************************************
// ********************* DELETE *********************

// Worker Sagas
function* deletePassTemplateSaga() {
  yield takeEvery(deletePassTemplate.type, deletePassTemplateRequest);
}

function* passTemplateDeletedSaga() {
  yield takeLatest(passTemplateDeleted.type, deletePassTemplateResponse);
}

function* passTemplateNotDeletedSaga() {
  yield takeLatest(passTemplateNotDeleted.type, deletePassTemplateError);
}

// Request
function* deletePassTemplateRequest(action: PayloadAction<PassTemplate>) {
  try {
    const { payload: passTemplate } = action;
    yield apiService.execute({
      url: `PassTemplates/${passTemplate.Id}`,
      method: ApiRequestType.DELETE,
      data: passTemplate,
    });
  } catch ({ message }) {
    yield put({ type: passTemplateNotDeleted.type, payload: { msg: { message } } });
  }
}

// Response
function deletePassTemplateResponse(action: PayloadAction<SignalRAction>) {
  notificationService.showSuccess('passTemplates.notifications.delete');
  action.payload.history.push(`/PassTemplates`);
}

// Error
function deletePassTemplateError(action: PayloadAction<SignalRAction>) {
  notificationService.showErrorWithContent('passTemplates.notifications.deleteFailed', action?.payload?.msg.message);
}

// **************************************************
// ********************* DELETE All *****************

// Worker Sagas
function* deletePassTemplatesSaga() {
  yield takeEvery(deletePassTemplates.type, deletePassTemplatessRequest);
}

function* passTemplatesDeletedSaga() {
  yield takeLatest(passTemplatesDeleted.type, deletePassTemplatesResponse);
}

function* passTemplatesNotDeletedSaga() {
  yield takeLatest(passTemplatesNotDeleted.type, deletePassTemplatesError);
}

// Request
function* deletePassTemplatessRequest(action: PayloadAction<Array<PassTemplate>>) {
  try {
    const { payload: passTemplates } = action;
    yield apiService.execute({
      url: `PassTemplates`,
      method: ApiRequestType.DELETE,
      data: { ids: passTemplates.map((passTemplate) => passTemplate.Id) },
    });
  } catch ({ message }) {
    yield put({ type: passTemplatesNotDeleted.type, payload: { msg: { message } } });
  }
}

// Response
function deletePassTemplatesResponse() {
  notificationService.showSuccess('passTemplates.notifications.deleteAll');
}

// Error
function deletePassTemplatesError(action: PayloadAction<SignalRAction>) {
  notificationService.showErrorWithContent('passTemplates.notifications.deleteAllFailed', action?.payload?.msg.message);
}

// **************************************************
// ********************* FETCH *********************

// Worker Sagas
function* fetchPassTemplatesSaga() {
  yield takeEvery(fetchPassTemplates.type, fetchPassTemplatesRequest);
}

function* passTemplatesFetchedSaga() {
  yield takeLatest(passTemplatesFetched.type, fetchPassTemplatesResponse);
}

function* passTemplatesNotFetchedSaga() {
  yield takeLatest(passTemplatesNotFetched.type, fetchPassTemplatesError);
}

// Request
function* fetchPassTemplatesRequest(action: PayloadAction<PassTemplate>) {
  try {
    const { payload: passTemplate } = action;
    yield apiService.execute({
      url: `PassTemplates/Fetch/${passTemplate.Id}`,
      method: ApiRequestType.POST,
    });
  } catch ({ message }) {
    yield put({ type: passTemplatesNotFetched.type, payload: { msg: { message } } });
  }
}

// Response
function fetchPassTemplatesResponse() {
  notificationService.showSuccess('passTemplates.notifications.fetch');
}

// Error
function fetchPassTemplatesError(action: PayloadAction<SignalRAction>) {
  notificationService.showErrorWithContent('passTemplates.notifications.fetchFailed', action?.payload?.msg.message);
}

// **************************************************
// *********************  UPDATE PASS TEMPLATE ASSET *********************

// Worker Sagas
function* updatePassTemplateAssetSaga() {
  yield takeEvery(updatePassTemplateAsset.type, updatePassTemplateAssetRequest);
}

function* passTemplateAssetUpdatedSaga() {
  yield takeLatest(passTemplateAssetUpdated.type, updatePassTemplateAssetResponse);
}

function* passTemplateAssetNotUpdatedSaga() {
  yield takeLatest(passTemplateAssetNotUpdated.type, updatePassTemplateAssetError);
}

// Request
function* updatePassTemplateAssetRequest(action: PayloadAction<PassTemplateAsset>) {
  try {
    const { payload: data } = action;
    const traceId = crypto.randomUUID();
    yield apiService.execute({
      url: `PassTemplates/${data.Id}/PassTemplateAsset`,
      method: ApiRequestType.PUT,
      data,
      traceId,
    });
    yield put({ type: setTraceId.type, payload: traceId });
  } catch ({ message }) {
    yield put({ type: passTemplateAssetNotUpdated.type, payload: { msg: { message } } });
  }
}

// Response
function updatePassTemplateAssetResponse() {
  notificationService.showSuccess('passTemplatesAsset.notifications.update');
}

// Error
function updatePassTemplateAssetError(action: PayloadAction<SignalRAction>) {
  notificationService.showErrorWithContent(
    'passTemplatesAsset.notifications.updateFailed',
    action?.payload?.msg.message
  );
}

// **************************************************
// ********************* DELETE PASS TEMPLATE ASSET *********************

// Worker Sagas
function* deletePassTemplateAssetSaga() {
  yield takeEvery(deletePassTemplateAsset.type, deletePassTemplateAssetRequest);
}

function* passTemplateAssetDeletedSaga() {
  yield takeLatest(passTemplateAssetDeleted.type, deletePassTemplateAssetResponse);
}

function* passTemplateAssetNotDeletedSaga() {
  yield takeLatest(passTemplateAssetNotDeleted.type, deletePassTemplateAssetError);
}

// Request
function* deletePassTemplateAssetRequest(action: PayloadAction<{ Id: string; TemplateId: string }>) {
  try {
    const { payload: passTemplateAsset } = action;
    yield apiService.execute({
      url: `PassTemplates/${passTemplateAsset.Id}/PassTemplateAsset`,
      method: ApiRequestType.DELETE,
      data: passTemplateAsset,
    });
  } catch ({ message }) {
    yield put({ type: passTemplateAssetNotDeleted.type, payload: { msg: { message } } });
  }
}

// Response
function deletePassTemplateAssetResponse() {
  notificationService.showSuccess('passTemplatesAsset.notifications.delete');
}

// Error
function deletePassTemplateAssetError(action: PayloadAction<SignalRAction>) {
  notificationService.showErrorWithContent(
    'passTemplatesAsset.notifications.deleteFailed',
    action?.payload?.msg.message
  );
}

// **************************************************
// *********************  UPDATE PASS TEMPLATE ASSET POSITION *********************

// Worker Sagas
function* updatePassTemplateAssetPositionSaga() {
  yield takeEvery(updatePassTemplateAssetPosition.type, updatePassTemplateAssetPositionRequest);
}

function* passTemplateAssetPositionUpdatedSaga() {
  yield takeLatest(passTemplateAssetPositionUpdated.type, updatePassTemplateAssetPositionResponse);
}

function* passTemplateAssetPositionNotUpdatedSaga() {
  yield takeLatest(passTemplateAssetPositionNotUpdated.type, updatePassTemplateAssetPositionError);
}

// Request
function* updatePassTemplateAssetPositionRequest(action: PayloadAction<PassTemplateAsset>) {
  try {
    const { payload: passTemplateAsset } = action;
    yield apiService.execute({
      url: `PassTemplates/${passTemplateAsset.Id}/PassTemplateAssetPositions`,
      method: ApiRequestType.PUT,
      data: {
        Id: passTemplateAsset.Id,
        TemplateId: passTemplateAsset.TemplateId,
        X: passTemplateAsset.X,
        Y: passTemplateAsset.Y,
      },
    });
  } catch ({ message }) {
    yield put({ type: passTemplateAssetPositionNotUpdated.type, payload: { msg: { message } } });
  }
}

// Response
function updatePassTemplateAssetPositionResponse() {
  notificationService.showSuccess('passTemplatesAsset.notifications.update');
}

// Error
function updatePassTemplateAssetPositionError(action: PayloadAction<SignalRAction>) {
  notificationService.showErrorWithContent(
    'passTemplatesAssetPosition.notifications.updateFailed',
    action?.payload?.msg.message
  );
}

// **************************************************
// ********************* EXPORT SAGAS ***************

export default function* sagas() {
  yield all([
    // Create
    createPassTemplateSaga(),
    passTemplateCreatedSaga(),
    passTemplateNotCreatedSaga(),
    // Update
    updatePassTemplateSaga(),
    passTemplateUpdatedSaga(),
    passTemplateNotUpdatedSaga(),
    // Delete
    deletePassTemplateSaga(),
    passTemplateDeletedSaga(),
    passTemplateNotDeletedSaga(),
    // Delete All
    deletePassTemplatesSaga(),
    passTemplatesDeletedSaga(),
    passTemplatesNotDeletedSaga(),
    // Fetch
    fetchPassTemplatesSaga(),
    passTemplatesFetchedSaga(),
    passTemplatesNotFetchedSaga(),
    // Update PassTemplate Assets
    updatePassTemplateAssetSaga(),
    passTemplateAssetUpdatedSaga(),
    passTemplateAssetNotUpdatedSaga(),
    // Delete PassTemplate Assets
    deletePassTemplateAssetSaga(),
    passTemplateAssetDeletedSaga(),
    passTemplateAssetNotDeletedSaga(),
    // Update PassTemplate Assets Positions
    updatePassTemplateAssetPositionSaga(),
    passTemplateAssetPositionUpdatedSaga(),
    passTemplateAssetPositionNotUpdatedSaga(),
  ]);
}
