import React, { useCallback, useMemo } from 'react';
import { Col, Form, Input, InputNumber, Modal, Row, Select, Slider } from 'antd';
import { Store } from 'antd/lib/form/interface';
import styled from 'styled-components';
import { useIntl } from 'react-intl';
// Types
import { Option } from 'antd/es/mentions';
import { UserDeleteOutlined } from '@ant-design/icons';
import { FormOptions, UseFormProps } from '../../types/Table';

// Models
// Hooks
import { useAppDispatch, useAppSelector } from '../App/useRedux';

// Actions
// Components
import { Translated } from '../../components/UI/Core';
import { Spinner } from '../../components/UI/Spinner/Spinner';
import { PassTemplateAsset, PassTemplateAssetType } from '../../models/PassTemplate';
import { deletePassTemplateAsset } from '../../store/PassTemplates/PassTemplates.redux';

const { confirm } = Modal;

// Styled
const StyledForm = styled(Form)`
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 10px;
`;

// Props
interface PassTemplatesReduxProps {
  initialValues?: Store;
  setTextElements?: React.Dispatch<React.SetStateAction<PassTemplateAsset[]>>;
  selectedAssetId?: string;
  assetsType?: PassTemplateAssetType;
  onSubmit?: (updatedTextElement: PassTemplateAsset) => void;
  setFileError?: (error: string | null) => void;
  fileError?: string | null;
}

const allowedTypes = [
  PassTemplateAssetType.Text, // 0
  PassTemplateAssetType.FirstName, // 2
  PassTemplateAssetType.LastName, // 3
  PassTemplateAssetType.Email, // 4
  PassTemplateAssetType.Department, // 5
];

// Hooks
export const usePassTemplatesAssetsRedux = <T extends object>({
  initialValues,
  setTextElements,
  selectedAssetId,
  assetsType,
  onSubmit,
  setFileError,
  fileError,
}: PassTemplatesReduxProps = {}) => {
  // Intl
  const intl = useIntl();

  // Redux
  const dispatch = useAppDispatch();
  const updating = useAppSelector(({ passTemplates }) => passTemplates?.updating ?? false);
  const error = useAppSelector(({ passTemplates }) => passTemplates?.error ?? false);

  const handleAssetChange = useCallback(
    (field: keyof PassTemplateAsset, value: any) => {
      setTextElements?.((prev) =>
        prev.map((element) => (element.Id === selectedAssetId ? { ...element, [field]: value } : element))
      );
    },
    [setTextElements, selectedAssetId]
  );

  const MAX_FILE_SIZE = 8 * 1024 * 1024; // 8MB

  const handleImageChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const file = e.target.files?.[0];
      if (file) {
        if (file.size > MAX_FILE_SIZE) {
          setFileError?.(
            intl.formatMessage({
              id: 'large.image.error.message',
            })
          );
          e.target.value = ''; // Reset input field
          return;
        }
        setFileError?.(null);

        const reader = new FileReader();
        reader.onload = (upload) => {
          const imageUrl = upload.target?.result as string;

          handleAssetChange('Image', {
            FileData: imageUrl,
            Url: imageUrl,
            MimeType: file.type,
          });

          handleAssetChange('Width', 100);
          handleAssetChange('Height', 50);
        };
        reader.readAsDataURL(file); // Read the image as base64
      }
    },
    [MAX_FILE_SIZE, handleAssetChange, intl, setFileError]
  );

  // Confirmation Modals
  const showDeleteAssetConfirm = useCallback(() => {
    if (selectedAssetId) {
      confirm({
        title: intl.formatMessage({
          id: 'passTemplatesAsset.confirm.delete',
        }),
        icon: <UserDeleteOutlined />,
        content: intl.formatMessage({
          id: 'passTemplatesAsset.confirm.deleteSub',
        }),
        okText: intl.formatMessage({
          id: 'app.yes',
          defaultMessage: 'Yes',
        }),
        cancelText: intl.formatMessage({
          id: 'app.no',
          defaultMessage: 'No',
        }),
        okType: 'danger',
        onOk: () => dispatch(deletePassTemplateAsset({ Id: selectedAssetId, TemplateId: initialValues?.Id })),
        onCancel: () => null,
      });
    }
  }, [intl, dispatch, selectedAssetId, initialValues?.Id]);

  const selectedAsset = initialValues?.Assets.find((asset: PassTemplateAsset) => asset.Id === selectedAssetId);

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const { key } = event;
    if (!/^\d$/.test(key)) {
      event.preventDefault();
    }
  };

  // Components
  const CreatePassTemplateAssetForm = useCallback(
    ({ form }: UseFormProps<T>) => (
      <StyledForm
        form={form}
        initialValues={selectedAsset}
        onFinish={(values) => onSubmit?.(values as PassTemplateAsset)}
      >
        <Spinner spinning={updating}>
          <Form.Item name="Id" hidden>
            <Input />
          </Form.Item>
          <Form.Item name="TempalteId" hidden>
            <Input />
          </Form.Item>
          <Row gutter={16}>
            {/* First Column */}
            {allowedTypes.includes(selectedAsset?.Type ?? -1) || allowedTypes.includes(assetsType ?? -1) ? (
              <Col className="gutter-row" span={8}>
                <div style={{ fontWeight: 700 }}>
                  {' '}
                  <span>
                    <Translated id="text.details" defaultMessage="Text" />
                  </span>
                </div>
                <Row gutter={16}>
                  <Col className="gutter-row" span={12}>
                    <Form.Item
                      label="Text"
                      name="Text"
                      labelCol={{ span: 6 }}
                      labelAlign="left"
                      wrapperCol={{ span: 16 }}
                    >
                      <Input onChange={(e) => handleAssetChange('Text', e.target.value)} />
                    </Form.Item>
                  </Col>
                  <Col className="gutter-row" span={12}>
                    <Form.Item label="Font Type" name="Font" labelCol={{ span: 6 }} wrapperCol={{ span: 16 }}>
                      <Select defaultValue="Arial" onChange={(value) => handleAssetChange('Font', value)}>
                        <Option value="Arial">Arial</Option>
                        <Option value="Times New Roman">Times New Roman</Option>
                        <Option value="Courier New">Courier New</Option>
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col className="gutter-row" span={12}>
                    <Form.Item label="Font Size" name="FontSize" labelCol={{ span: 6 }} wrapperCol={{ span: 16 }}>
                      <InputNumber
                        min={1}
                        max={100}
                        onChange={(value) => handleAssetChange('FontSize', value)}
                        style={{ width: '100%' }}
                        onKeyPress={handleKeyPress}
                      />
                    </Form.Item>
                  </Col>
                  <Col className="gutter-row" span={12}>
                    <Form.Item label="Font Color" name="FontColor" labelCol={{ span: 6 }} wrapperCol={{ span: 16 }}>
                      <Input type="color" onChange={(e) => handleAssetChange('FontColor', e.target.value)} />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
            ) : (
              <Col className="gutter-row" span={8}>
                <div style={{ fontWeight: 700 }}>
                  {' '}
                  <span>
                    <Translated id="image.details" defaultMessage="Text" />
                  </span>
                </div>
                <Row gutter={16}>
                  {assetsType !== PassTemplateAssetType.ProfilePicture && (
                    <Col className="gutter-row" span={12}>
                      <Form.Item
                        label="Upload Image"
                        labelCol={{ span: 8 }}
                        labelAlign="left"
                        wrapperCol={{ span: 16 }}
                      >
                        <input type="file" accept="image/*" onChange={handleImageChange} style={{ width: '100%' }} />
                        <p style={{ fontSize: '12px', color: '#666' }}>
                          <Translated id="large.image.error.help.message" />
                        </p>
                        {fileError && <p style={{ color: 'red' }}>{fileError}</p>}
                      </Form.Item>
                    </Col>
                  )}
                  <Col className="gutter-row" span={12}>
                    <Form.Item label="Display" name="DisplayMode" labelCol={{ span: 6 }} wrapperCol={{ span: 16 }}>
                      <Select defaultValue="Stretch" onChange={(e) => handleAssetChange('DisplayMode', e)}>
                        <Option value="Stretch">Stretch</Option>
                        <Option value="Contain">Contain</Option>
                        <Option value="Cover">Cover</Option>
                        <Option value="Fill">Fill</Option>
                        <Option value="None">None</Option>
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col className="gutter-row" span={12}>
                    <Form.Item
                      label="Width"
                      name="Width"
                      labelAlign="left"
                      labelCol={{ span: 8 }}
                      wrapperCol={{ span: 16 }}
                      help="Max: 800px, Min: 50px"
                    >
                      <InputNumber
                        min={50}
                        max={800}
                        onChange={(value) => handleAssetChange('Width', value)}
                        style={{ width: '100%' }}
                        onKeyPress={handleKeyPress}
                      />
                    </Form.Item>
                  </Col>
                  <Col className="gutter-row" span={12}>
                    <Form.Item
                      label="Height"
                      name="Height"
                      labelCol={{ span: 6 }}
                      wrapperCol={{ span: 16 }}
                      help="Max: 350, Min: 50px"
                    >
                      <InputNumber
                        min={50}
                        max={350}
                        onChange={(value) => handleAssetChange('Height', value)}
                        style={{ width: '100%' }}
                        onKeyPress={handleKeyPress}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
            )}

            {/* Second Column */}
            <Col className="gutter-row" span={8}>
              <div style={{ fontWeight: 700 }}>
                {allowedTypes.includes(selectedAsset?.Type ?? -1) || allowedTypes.includes(assetsType ?? -1) ? (
                  <span>
                    <Translated id="text.decoration" defaultMessage="Text" />
                  </span>
                ) : (
                  <span>
                    <Translated id="image.decoration" defaultMessage="Text" />
                  </span>
                )}
              </div>
              <Row gutter={16}>
                <Col className="gutter-row" span={12}>
                  <Form.Item
                    label="Opacity"
                    name="Opacity"
                    labelCol={{ span: 12 }}
                    labelAlign="left"
                    wrapperCol={{ span: 16 }}
                  >
                    <Slider min={0.2} max={1} step={0.1} onChange={(value) => handleAssetChange('Opacity', value)} />
                  </Form.Item>
                </Col>
                <Col className="gutter-row" span={12}>
                  <Form.Item
                    label="Border Radius 0%"
                    name="BorderRadius"
                    labelCol={{ span: 11 }}
                    wrapperCol={{ span: 16 }}
                  >
                    <Slider min={0} max={50} step={1} onChange={(value) => handleAssetChange('BorderRadius', value)} />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col className="gutter-row" span={12}>
                  <Form.Item
                    label="Shadow Size"
                    name="ShadowSize"
                    labelCol={{ span: 12 }}
                    wrapperCol={{ span: 16 }}
                    labelAlign="left"
                  >
                    <Slider min={0} max={50} step={1} onChange={(value) => handleAssetChange('ShadowSize', value)} />
                  </Form.Item>
                </Col>
                <Col className="gutter-row" span={12}>
                  <Form.Item
                    label="Shadow Color"
                    name="ShadowColor"
                    labelCol={{ span: 11 }}
                    wrapperCol={{ span: 16 }}
                    labelAlign="left"
                  >
                    <Input type="color" onChange={(e) => handleAssetChange('ShadowColor', e.target.value)} />
                  </Form.Item>
                </Col>
              </Row>
            </Col>

            {/* Third Column */}
            <Col className="gutter-row" span={8}>
              <div style={{ fontWeight: 700 }}>
                {allowedTypes.includes(selectedAsset?.Type ?? -1) || allowedTypes.includes(assetsType ?? -1) ? (
                  <span>
                    <Translated id="text.position" defaultMessage="Text" />
                  </span>
                ) : (
                  <span>
                    <Translated id="image.position" defaultMessage="Text" />
                  </span>
                )}
              </div>
              <Row gutter={16}>
                <Col className="gutter-row" span={12}>
                  <Form.Item label="X-Position" name="X" labelCol={{ span: 6 }} wrapperCol={{ span: 16 }}>
                    <InputNumber
                      min={1}
                      max={350}
                      onChange={(value) => handleAssetChange('X', Number(value))}
                      style={{ width: '100%' }}
                      onKeyPress={handleKeyPress}
                    />
                  </Form.Item>
                </Col>
                <Col className="gutter-row" span={12}>
                  <Form.Item label="Y-Position" name="Y" labelCol={{ span: 6 }} wrapperCol={{ span: 16 }}>
                    <InputNumber
                      min={1}
                      max={200}
                      onChange={(value) => handleAssetChange('Y', Number(value))}
                      style={{ width: '100%' }}
                      onKeyPress={handleKeyPress}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col className="gutter-row" span={12}>
                  <Form.Item label="Layer" name="Layer" labelCol={{ span: 6 }} wrapperCol={{ span: 16 }}>
                    <InputNumber
                      min={0}
                      max={200}
                      onChange={(value) => handleAssetChange('Layer', value)}
                      style={{ width: '100%' }}
                      onKeyPress={handleKeyPress}
                    />
                  </Form.Item>
                </Col>
                <Col className="gutter-row" span={12}>
                  <Form.Item label="Rotation" name="Rotation" labelCol={{ span: 6 }} wrapperCol={{ span: 16 }}>
                    <Slider min={0} max={360} onChange={(value) => handleAssetChange('Rotation', value)} />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
          </Row>
        </Spinner>
      </StyledForm>
    ),
    [selectedAsset, updating, assetsType, handleImageChange, fileError, onSubmit, handleAssetChange]
  );

  // Form options
  const formOptions = useMemo(
    () =>
      ({
        endpoint: 'PassTemplates',
        Form: CreatePassTemplateAssetForm,
        labels: {
          createButton: <Translated id="passTemplates.form.create" />,
          drawerForm: <Translated id="passTemplates.form.edit" />,
          submitButton: <Translated id="form.editButton" />,
          deleteButton: <Translated id="form.removeButton" />,
        },
      } as FormOptions<T>),
    [CreatePassTemplateAssetForm]
  );

  const drawerSize = 'large' as const;

  return useMemo(
    () => ({
      formOptions,
      updating,
      error,
      size: drawerSize,
      showDeleteAssetConfirm,
    }),
    [formOptions, updating, error, showDeleteAssetConfirm]
  );
};
